import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout/Layout"
import Container from "../../../components/layout/Container"
import Type from "../../../components/typography/Type"
import SearchNav from "../../../components/search/SearchNav"
import ScreenCard from "../../../components/cards/ScreenCard"

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";



import { isLoggedIn } from "../../../services/auth"
import LoginPrompt from "../../../components/app/loginPrompt"





export default function Pages( {data} ) {
return (
  <Layout>
  <Container>
  
  {isLoggedIn() ? (
  <>
  

  <div className="flex flex-col lg:flex-row lg:space-x-16 lg:mt-20 mt-0">
<div className="w-3/12">
 <SearchNav industry={data.airtable.data.Path}/>
</div>
<div className="w-9/12">

<Type type="h3">{data.airtable.data.GroupName} journeys</Type>

  <div className="grid grid-cols-1 gap-8">
{data.airtable.data.Journeys.map((array) => (
  <div className="mt-10 w-full">
<Type type="h4">{array.data.OrganisationTxt}</Type>

  <Swiper
  style={{
    "--swiper-navigation-color": "#000",
    "--swiper-pagination-color": "#000",
  }}
    // install Swiper modules\
    modules={[Navigation]}
    slidesPerView={4}
    spaceBetween={30}
    loop={false}
    navigation
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    className={array.data.Path}
  >
  {array.data.Screens.map((screenArray) => (
    <SwiperSlide> 
  <ScreenCard
    url={screenArray.data.Path}
    image={screenArray.data.ThumbS3}
    title={screenArray.data.Name}
    />
    </SwiperSlide>
  ))}
  </Swiper>
  </div>
))}

  </div>
</div>
  </div>
  
  
   
    </>
  ) : (
    <>
      <LoginPrompt/>
    </>
  )}

  </Container>
  </Layout>
  )
}



export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "JourneyGroups" },
    data: { Path: { eq: $Path } }) {
      data {
        GroupName
        Journeys {
          data {
            JourneyName
            Path
            OrganisationTxt
            Screens {
              data {
                Name
                Path
                ThumbS3
              }
            }
          }
        }
        
      }
    }
  }
`
